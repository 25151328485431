/**
 * @generated SignedSource<<9e4810131c27667aa39f77034fc51ca2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RecommendUserInput = {
  clientMutationId?: string | null;
  email?: string | null;
  location?: LocationInput | null;
  phone?: string | null;
  skill_title?: string | null;
  user_id?: string | null;
  value?: boolean | null;
};
export type LocationInput = {
  city?: string | null;
  country?: string | null;
  country_code?: string | null;
  is_anonymized?: boolean | null;
  latitude?: number | null;
  line1?: string | null;
  line2?: string | null;
  longitude?: number | null;
  note?: string | null;
  offset?: string | null;
  postal_code?: string | null;
  state?: string | null;
  zoom_level?: number | null;
};
export type RecommendUserMutation$variables = {
  input: RecommendUserInput;
};
export type RecommendUserMutation$data = {
  readonly recommendUser: {
    readonly deletedUserId: string | null;
    readonly deletedViewerId: string | null;
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly user: {
      readonly id: string;
      readonly recommendations: {
        readonly id: string;
        readonly received: {
          readonly totalCount: number | null;
        } | null;
      } | null;
      readonly viewerHasRecommended: boolean | null;
    } | null;
    readonly userEdge: {
      readonly node: {
        readonly avatar: {
          readonly id: string;
          readonly smallThumbnail: string | null;
          readonly thumbnail: string | null;
        } | null;
        readonly first_name: string | null;
        readonly id: string;
        readonly last_name: string | null;
        readonly profile_color: string | null;
        readonly recommendations: {
          readonly id: string;
          readonly received: {
            readonly totalCount: number | null;
          } | null;
        } | null;
        readonly subtitle: string | null;
        readonly username: string | null;
      } | null;
    } | null;
    readonly viewerEdge: {
      readonly node: {
        readonly avatar: {
          readonly id: string;
          readonly smallThumbnail: string | null;
        } | null;
        readonly first_name: string | null;
        readonly id: string;
        readonly last_name: string | null;
        readonly profile_color: string | null;
        readonly username: string | null;
      } | null;
    } | null;
  } | null;
};
export type RecommendUserMutation = {
  response: RecommendUserMutation$data;
  variables: RecommendUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "first_name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last_name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profile_color",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "smallThumbnail",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "UserEdge",
  "kind": "LinkedField",
  "name": "viewerEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v8/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "UserRecommendations",
  "kind": "LinkedField",
  "name": "recommendations",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserConnection",
      "kind": "LinkedField",
      "name": "received",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "UserEdge",
  "kind": "LinkedField",
  "name": "userEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subtitle",
          "storageKey": null
        },
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thumbnail",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v10/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerHasRecommended",
      "storageKey": null
    },
    (v10/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedViewerId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedUserId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecommendUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RecommendUserPayload",
        "kind": "LinkedField",
        "name": "recommendUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecommendUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RecommendUserPayload",
        "kind": "LinkedField",
        "name": "recommendUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c4aa7a4e83004de72409038687c918e1",
    "id": null,
    "metadata": {},
    "name": "RecommendUserMutation",
    "operationKind": "mutation",
    "text": "mutation RecommendUserMutation(\n  $input: RecommendUserInput!\n) {\n  recommendUser(input: $input) {\n    error {\n      message\n      id\n    }\n    viewerEdge {\n      node {\n        id\n        first_name\n        last_name\n        username\n        profile_color\n        avatar {\n          id\n          smallThumbnail\n        }\n      }\n    }\n    userEdge {\n      node {\n        id\n        first_name\n        last_name\n        username\n        subtitle\n        profile_color\n        avatar {\n          id\n          smallThumbnail\n          thumbnail\n        }\n        recommendations {\n          id\n          received {\n            totalCount\n          }\n        }\n      }\n    }\n    user {\n      id\n      viewerHasRecommended\n      recommendations {\n        id\n        received {\n          totalCount\n        }\n      }\n    }\n    deletedViewerId\n    deletedUserId\n  }\n}\n"
  }
};
})();

(node as any).hash = "e9b7da6e91f9c2b791171e9ceafc0eaf";

export default node;
